// extracted by mini-css-extract-plugin
export const selectAmountSection = "DonationBox-module--selectAmountSection--18BiR";
export const selectAmountHeader = "DonationBox-module--selectAmountHeader--3iHEL";
export const selectAmountButtons = "DonationBox-module--selectAmountButtons--1w7gF";
export const selectAmountButton = "DonationBox-module--selectAmountButton--3HS3Q";
export const buttonSelected = "DonationBox-module--buttonSelected--Es6ds";
export const goToPayment = "DonationBox-module--goToPayment--UtHQJ";
export const applePayButton = "DonationBox-module--applePayButton--1NSCt";
export const otherPayButton = "DonationBox-module--otherPayButton--LjsWm";
export const confirmationBox = "DonationBox-module--confirmationBox--29x3P";
export const confirmationMessage = "DonationBox-module--confirmationMessage--3JaE0";
export const confirmationButton = "DonationBox-module--confirmationButton--3Gyrf DonationBox-module--selectAmountButton--3HS3Q";